html {
  font-family: "Montserrat", sans-serif;
}

h4 {
  margin-top: 10px;
}

sub, sup {
  color: #777
}

/* prevents the content on the page from shifting when the modal opens */
.modal-open[style] {
  padding-right: 0px !important;
}

body {
  /* padding-right: 0px !important; */
  /* margin-right: 0px !important; */
}

.mapboxgl-ctrl-bottom-left {
  display: none !important;
}

.mapboxgl-ctrl-attrib {
  display: none !important;
}

.custom-tooltip {
  background: white;
  color: #333;
  box-shadow: 0 3px 14px rgb(0 0 0 / 40%);
  padding: 0.5rem;
  text-align: left;
  border-radius: 0.25rem;
}

.recharts-tooltip-wrapper {
  z-index: 1000;
}

.recharts-text, .recharts-cartesian-axis-tick-value {
  width: 60px;
}

.navbar-title {
  position: relative;
  top: 8px;
  font-size:1.5rem;
  text-transform: uppercase;
  color: rgb(85, 85, 85);
}

.navbar-title-sm {
  font-weight: 600;
  font-size:1rem;
  text-transform: uppercase;
  color: rgb(75, 75, 75);
  word-wrap: normal;
}

.map-border {
  border-color: #fff;
  border-width: 1.5px;
}

/* .navbar h2.navbar-title{
  font-weight: 600;
  font-size: 95%;
}

.nav-item, .nav-item button{
  text-align: center;
}
.nav-link{
}

.navbar-text{
  display: block;
}
.navbar-title{
  display: none;
  text-align: center;
}
.navbar-title-above, .navbar-title-above h2{
  display: block;
  text-align: center;
  font-size: 125%;
} */

.stats-wrapper{
  max-width: 960px;
  width: 100%;
  margin: 0 auto;
}

.big{
  font-size:1.25em;
}
.caps{
  text-transform: capitalize;
}
.color--green-light{
  color:#43AC23
}
.color--green-base{
  color:#50923D
}
.color--green-dark{
  color:#006B2A
}

.color--red-base {
  color:#CC0000;
}
.color--orange-base{
  color:#F8771E;
}
.color--gold-base{
  color:#B99B00;
}
.color--brown-base{
  color:#575047;
}
.color--gray-dark{
  color:#7F8184;
}
.color--gray-light{
  color:#EEEEEE;
}

.color--legend--urban {
  color: #C72C16;
}

.color--legend--ldr {
  color: #FA5C00;
}

.color--legend--slr {
  color: #116589;
}

.mobile-scroll-area{
  height:5px;
  display: block;
}
.strong{
  font-weight: bolder;
}

.stats--table {
  /* margin: 0 auto; */
  text-align: left;
  max-width: 960px;
  width: 100%;
}

.stats--table th{
  padding:.25em 1em;
  background-color:#7F8184;
  color:white;
  font-weight: bold;
}

.stats--table td{
  padding:.25em 1em;
  background-color:#EEEEEE;
}

.conv--table {
  margin: 0 auto;
}

.table-xs-td{
  padding: 0 !important;
}

.list-override{
  padding: 0;
  margin: 0;
  text-indent: 0;
  list-style: none;
}
.list-override ul{
  padding: 0;
  margin: 0;
  text-indent: 0;
}
.list-override ul li{
  padding: 0;
  margin: 0;
  text-indent: 0;
}

@media only screen 
   and (min-width : 992px) {

   .mobile-scroll-area{
    display: none;
  }
}

.legend-swatch {
  margin: 2px;
  height: 15px;
  width: 25px;
  /* border: solid #555;
  border-width: thin; */
  border-radius: 3px;
  box-shadow: 0.5px 0.5px 1px 0.5px rgba(0,0,0,0.3);
}

/* @media only screen 
   and (min-width : 450px) {

  .navbar-title{
    display: block;
  }
  .navbar-title-above{
    display: none;
  }
  .navbar-text{
    display: inherit;
  }

  .navbar h2.navbar-title{
    font-size: 100%;
  }
} */

.symbol-uhd {
  font-weight: bold;
  color: #C72C16 !important;
  
}

.symbol-ldr {
  font-weight: bold;
  color: #FA5C00 !important;
}

.map-column {
  padding: 0.25rem;
}

.map-wrapper {
  height: 420px;
}

.map-control-wrapper {
  width: 100%;
  padding: 0.25rem;
  /* position: absolute; */
}

.map-control-overlay-top {
  width: 100%;
  /* position: relative; */
  margin: 0px;
}

.map-control-overlay-bottom {
  width: 99%;
  /* position: relative; */
  margin: 2px 0px;
}

.map-control-component-wrapper {
  margin-bottom: 0.25rem;
}

.end-col-justify {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.basemap-selector-button {
  line-height: 1rem;
}

.about-toggle {
  /* padding: 0px 12px; */
  cursor: pointer;
}

.about-toggle-wrapper {
  position: absolute;
  bottom: 55px;
  right: 2px;
  width:40px;
  height: 50px;
  color: #757576 !important;
  font-weight: 500;
  background-color: transparent;
  border-color: transparent;
}

/* .map-control-title-wrapper {
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  white-space: "nowrap";
  vertical-align: "baseline";
}
*/

.map-control-title-text {
  background: #fff;
  border-radius: 2px;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-align: center;  
  white-space: "nowrap";
  padding: 4px 8px;
}

/* layer colors (for reference)*/

/* Selected Counties */
.countyOutline--paint--fill-outline-color {
  color: #0d6efd;
}
/* Selected States */
.stateOutline--paint--fill-outline-color {
  color: #c72c16;
}
.conusCounty--paint--fill-outline-color {
  color: #c6c9ca;
}
.conusState--paint--fill-outline-color {
  color: #c6c9ca;
}

.call-to-action-text { 
  font-size: 23.45px;
  line-height: 24px;
}
.call-to-action-button {
  background-color: #006B2A;
}

.mapboxgl-canvas {
  cursor: crosshair;
  }

.markdown-modal-title {
  font-size: 2rem;
}

.tooltip {
  padding: 0.5rem;
  text-align: left;
  border-radius: 0.25rem;
}
.tooltip-inner {
  color: #333 !important;
  background-color: #fff !important;
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0,0.2);
}
.tooltip-arrow {
  color: #fff !important;
  /* background-color: #fff !important; */
  /* box-shadow: 1px 1px 2px 1px rgba(0, 0, 0,0.2); */
}

.legend-toggle {
  z-index: 1000;
  position: relative;
}