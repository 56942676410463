body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.sidebar {
  position: absolute;
  left: -300px;
  height: 600px;
  width: 300px;
  transition: left 0.3s ease-in-out;
  background-color: "transparent";
}

.sidebar-toggle {
  position: absolute;
  right: -20px;
  height: 24px;
  width: 24px;
  z-index: 1;
}

.content {
  position: absolute;
  top: 600;
  left: 0;
  right: 0;
  height: 600px;
  transition: left 0.3s ease-in-out;
  background-color: lightgray;
}

.sidebar.open {
  left: 277px;
}

.content.open {
  left: 576px;
}

navbar-toggler-icon {
  color: "white"
}