li {
    line-height: 1.25rem;
}

h1 {
    font-size: 2.2rem;
}

h2 {
    margin-top: 1rem;
    font-size: 1.6rem;
}

p {
    font-size: 95%;
    /* margin-bottom: 0.25rem;
    margin-top: 0.5rem; */
}

ul {
    margin-top: 0.25rem;
}